<template>
  <li v-if="link" class="min-h-40">
    <component :is="headerTag">
      <NuxtLink
        v-if="withIcon"
        :to="link.url?.path"
        class="link text-lg lg:text-xl flex -mb-10"
      >
        <MediaIcon
          v-if="icon"
          v-bind="icon"
          class="!w-40 !h-40 shrink-0"
          aria-hidden="true"
        />
        <SpriteSymbol
          v-else
          name="baselstab"
          class="!w-40 !h-40 shrink-0"
          aria-hidden="true"
        />
        <span class="mb-10">{{ link.label }}</span>
      </NuxtLink>

      <NuxtLink
        v-else
        :to="link.url?.path"
        class="link text-lg lg:text-xl with-icon"
      >
        <span>{{ link.label }}</span>
      </NuxtLink>
    </component>
    <ul
      v-if="children.length"
      class="mt-5 grid gap-8 xl:mt-10"
      :class="withIcon ? 'pl-[50px]' : 'pl-25'"
    >
      <li v-for="(child, key) in children" :key="key">
        <VuepalLink
          class="link font-normal text-base lg:text-lg inline-block"
          :to="child.to"
        >
          {{ child.title }}
        </VuepalLink>
      </li>
    </ul>
  </li>
</template>

<script lang="ts" setup>
import type {
  MediaIconFragment,
  OrganizationMenuElementFragment,
  OrganizationMenuElementSubtreeFragment,
  TopicTeaserFragment,
} from '#graphql-operations'

type MenuListLink =
  | TopicTeaserFragment
  | OrganizationMenuElementFragment
  | OrganizationMenuElementSubtreeFragment

const props = defineProps<{
  link?: MenuListLink['link']
  subtree?: MenuListLink['subtree']
  heading?: 'h2' | 'h3'
  withIcon?: boolean
}>()

const children = computed(() => {
  return (
    props.subtree
      ?.filter((v) => v.link?.url?.path)
      .map((v) => {
        return {
          title: v.link.label,
          to: v.link.url?.path,
        }
      }) || []
  )
})

const icon = computed<MediaIconFragment | undefined>(() => {
  const url = props.link?.url
  if (url && 'entity' in url) {
    const entity = url.entity
    if (entity && 'icon' in entity) {
      return entity.icon
    }
  }
  return undefined
})

const headerTag = computed(() => props.heading ?? 'h2')

defineOptions({
  name: 'MenuListItem',
})
</script>
